import React, { Component } from 'react';

class Footer extends Component {
    state = {  }
    render() { 
        return ( 
            <footer>

            </footer>
         );
    }
}
 
export default Footer ;