import React, { Component } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import image1 from '../../Assets/images/welcome.png';
import image2 from '../../Assets/images/pta-pic.png';
import image3 from '../../Assets/images/po-opt-pic.png';
import image4 from '../../Assets/images/prod-fore-pic.png';
import image5 from '../../Assets/images/dig-well-pic.png';
import image6 from '../../Assets/images/well_rep.png';
import image7 from '../../Assets/images/stpf.png';
import image8 from '../../Assets/images/pta.png';
import image9 from '../../Assets/images/Production_Optimiz.png';
import Slider from "react-slick";
import ShowMoreText from "react-show-more-text";

const photos = [
    {
        name: 'photo 1',
        url: image3, 
    },
    {
        name: 'photo 2',
        url: image4, 
    },
    {
        name: 'photo 3',
        url: image5, 
    },
    {
        name: 'photo 4',
        url: image2, 
    },
    {
        name: 'photo 5',
        url: image1, 
    },
    {
        name: 'photo 6',
        url: image6, 
    },
    {
        name: 'photo 7',
        url: image7, 
    },
    {
        name: 'photo 8',
        url: image8, 
    },
    {
        name: 'photo 9',
        url: image9, 
    }
]

class Homepage extends Component {
    state = {  }
    render() { 
        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            arrows: true,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            centerMode: true,
            cssEase: "linear",
            className: 'slides'
        }
        return ( 
            <div className="container-fluid">
                <br />
                
                <Slider {...settings}>
                    {photos.map((photo)=> {
                        return (
                            <div>
                                <img width="100%" style={{paddingRight:0}}src={photo.url} alt="images"/>
                            </div>
                        )
                    }
                    )}
                </Slider>

                <br />
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 style={{fontSize:30}}><b>OUR MISSION</b></h3>
                            <p style={{borderStyle:"ridge"}}> 
                                "To create a state-of-the-art technology company that leverages on
                                more than three decades of experience and novel technology to bring
                                improvement to your organization's processes". 
                            </p>   
                        </div> 
                
                        <div className="col">
                            <h3 style={{fontSize:30}}><b>OUR COMPANY</b></h3>
                            <ShowMoreText
                                    /* Default options */
                                    lines={3}
                                    more='Show more'
                                    less='Show less'
                                    anchorClass=''
                                    onClick={this.executeOnClick}
                                    expanded={false}
                                    width={550}
                                >
                                     <p>
                                        <b style={{fontSize:30}}><i>eTe</i>Optimiza</b> is a technology company that started in 2020. We specialize in software applications for oil, gas, and renewable industries.
                                    </p>
                                    <p>    
                                        Our software applications are customized for your needs. 
                                        The  products include production optimization, production improvement collaboration, pressure transient analysis automation, short term production forecasts, and well engineering reporting tools.
                                    </p>
                                    <p>
                                        eTeOptimiza builds custom tools that automate the process that petroleum and production
                                        engineers and managers use to deliver increased production and deliver wells' potential.
                                    </p>
                                    <p>
                                        You can navigate to the products section to learn more about our products.
                                    </p>    
                            </ShowMoreText>                 
                        </div>
                    </div>
                    
                </div>

            </div>
         );
    }
}
 
export default Homepage ;